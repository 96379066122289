.custom-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-loader div {
  width: 10px;
  height: 10px;
  background-color: #0389c9; /* Loader color */
  border-radius: 50%;
  margin: 0 5px;
  animation: bounce 0.6s infinite alternate;
}

.dot1 {
  animation-delay: -0.4s;
}

.dot2 {
  animation-delay: -0.2s;
}

@keyframes bounce {
  to {
    transform: translateY(-10px);
  }
}
/* Add CSS for rotating animation */
/* Add CSS for scaling animation */
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Apply the scaling animation to the image */
.scaling-image {
  animation: scale 2s ease-in-out infinite; /* Adjust animation duration and timing function as needed */
}
