:root {
  --main-color: #0389c9;
}
.ReactLoaderSpinner {
  color: #0389c9 !important;
}

.full_model .modal-content {
  background-color: rgba(255, 255, 255, 0.8);
}

.like-text {
  color: var(--main-color);
}
.like-admin-event {
  pointer-events: none;
}
.alertify .ajs-ok {
  background-color: var(--main-color);
  color: #fff;
  border: none;
  border-radius: 0.2rem;
}

.alertify .ajs-cancel {
  background-color: #f46a6a;
  color: #fff;
  border: none;
  border-radius: 0.2rem;
}
.alertify .ajs-header {
  color: var(--main-color);
  font-weight: 500;
  font-size: 1.2rem;
}

.alertify .ajs-message {
  color: #333;
}

.alertify-notifier .ajs-message.ajs-error,
.alertify-notifier .ajs-message.ajs-success {
  color: white !important;
}
.ajs-content .ajs-input {
  width: 100%;
}

.card-hover {
  transition: transform 0.3s ease;
}
.card-hover:hover {
  transform: scale(1.1);
}
/* .post-comment-icon[title]:hover::after {
  content: attr(title);
  position: absolute;
  background-color: black; 
  padding: 5px; 
  border-radius: 3px; 
  font-size: 12px; 
  white-space: nowrap; 
  pointer-events: none;
  opacity: 1; 
  top: 100%; 
  right: 10%; 
  transform: translateX(-50%); 
} */
.user-img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  position: relative;
}
.user-img .available-stats {
  position: absolute;
  right: 0;
  top: 0;
  width: 12px;
  height: 12px;
  border: 3px solid #ffffff;
  border-radius: 100%;
}
.user-img .available-stats.online {
  background-color: #2bc60c;
}
.user-img .available-stats.offline {
  background-color: #ff2f25;
}

.TwitterLogin {
  border: none !important;
  outline: none !important;
}

.google-btn {
  border-radius: 2rem !important;
  padding: 0 4px !important;
}

.custom-err-msg {
  color: #dc3545 !important;
  font-size: 0.875em !important;
}

.blue-color {
  color: #0389c9;
}
.youtube {
  background: linear-gradient(115deg, #ebe9e5, #ee2a2a, #ee2a2a);
}
.instagram {
  background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);
}

.tiktok {
  background: linear-gradient(115deg, #1a1918, #ee2aad, #28bdd7);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.allow-only-subscriber {
  cursor: pointer;
  filter: blur(0.3rem);
  position: relative;
  pointer-events: none;
}
.only-subscriber-container {
  cursor: pointer;
  position: relative;
}
.only-subscriber-container:hover .middle {
  opacity: 1;
  filter: blur(0rem);
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.text {
  color: white;
}
