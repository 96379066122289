:root {
  --main-color: #0389c9;
  --black-color:#000
}
.mui-custom-error {
  font-size: 0.75rem !important;
  line-height: 1.66 !important;
  letter-spacing: 0.03333em !important;
  text-align: left !important;
  margin-top: 4px !important;
  margin-right: 14px !important;
  margin-bottom: 0 !important;
  margin-left: 14px !important;
}
.custom-black-btn{
  background-color: var(--black-color);
  border-radius: .5rem;
}
.product-name {
  color: var(--main-color);
  /* border: 1px dashed  var(--main-color); */
  font-weight: 600;
  text-align: center;
  padding: 10px;
  box-shadow: var(--main-color) 0px 3px 6px;
}
.product-order-list {
  border-bottom: 0.1rem solid rgb(214, 211, 211) !important;
}
td,
th {
  vertical-align: middle;
}

.btn-icon {
  vertical-align: middle;
  margin-right: .3rem;
}
.creater-container {
  position: relative;
  min-height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: "#edf7fb";
}
.creater-form-container {
  max-width: 630px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}
.creater-heading {
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  line-height: 30px;
}
.red-hover:hover {
  /* cursor: pointer; */
  border-color: #fe4653;
  color: #fe4653;
}
.custom-border {
  border: 1px dashed grey;
  border-radius: 0.5rem;
}
.toggle-btn > .layer,
.toggle-btn-bg {
  background-color: rgb(182, 177, 177) !important;
  z-index: 1 !important;
}
.bread-heading {
  font-weight: bold;
}

.user-profile-cover {
  height: 100%;
  width: 100%;
  background-size: contain !important;
}

.post-div {
  width: calc(100% - 300px) !important;
}
.react-search-icon {
  position: absolute;
  right: 20px;
  bottom: 13px;
}
.react-search-icon:hover {
  cursor: pointer;
  transform: scale(1.3);
  transition: all linear 0.3s;
}
.empty-list-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  /* background-color: #f7f7f7; */
  border-radius: 5px;
  /* border: 1px solid #ddd; */
}

.no-list-message {
  font-size: 1.5rem;
  color: #999 !important;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-align: center;
  margin-bottom: 10px;
  font-style: italic;
}

.password-custom-style {
  cursor: pointer;
  /* position: absolute; */
  top: 2.2rem !important;
  /* top: 3rem !important; */
  right: 2rem !important;
}

.lg-password-custom-style {
  cursor: pointer;
  top: 3rem !important;
  right: 2rem !important;
}

/* Add these styles to your CSS file */

.post-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
  padding: 0.625rem;
  background-color: #fff;
  max-height: 300px;
  overflow-y: auto;
}
.post-card::-webkit-scrollbar {
  width: 8px;
}
.post-card::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 4px;
}
.post-card::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}
.comment-box {
  max-height: calc(100vh - 22.1rem);
  overflow-y: hidden;
  transition: overflow-y 0.3s ease;
  padding: 0 5px;
}
.custom-comments {
  height: calc(100vh - 330px);
  overflow: auto;
  padding: 0 35px;
}
.comment-box:hover {
  overflow-y: auto;
}
.comment-box::-webkit-scrollbar {
  width: 3px;
}
.comment-box::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 4px;
}
.comment-box::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}
.textarea-gradiant {
  width: 100% !important;
  height: 100% !important;
  padding: 1rem !important;
  text-align: center !important;
}
.about-content::-webkit-scrollbar,
.textarea-gradiant::-webkit-scrollbar,
.messages-content::-webkit-scrollbar {
  width: 3px;
}

.about-content::-webkit-scrollbar-track,
.messages-content::-webkit-scrollbar-track,
.textarea-gradiant::-webkit-scrollbar-track {
  background: transparent;
}

.about-content::-webkit-scrollbar-thumb,
.textarea-gradiant::-webkit-scrollbar-thumb,
.messages-content::-webkit-scrollbar-thumb {
  background: #0389c9;
  border-radius: 10px;
}

.about-content::-webkit-scrollbar-thumb:hover {
  background: #0389c9;
}

.post-content {
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.post-media {
  position: relative;
  width: 100%;
  /* height: 200px; */
}

.post-image,
.post-video {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.post-all-btn {
  position: absolute;
  top: 0%;
  /* right: 0%; */
  display: flex;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.2);
  height: 98%;
  width: 98%;
  padding: 2rem;
}
.remove-all-btn {
  font-size: 1.6rem;
  color: #fe4653;
}

.post-comment-icon {
  cursor: pointer;
  color: #0389c9;
}

.gr-16 {
  background-color: #fff;
  border: 1px solid #999;
}

.slider-container {
  position: relative;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  /* background-color: #fff;  */
  padding: 10px;
  border-radius: 50%;
  z-index: 1;
  color: #000;
}
.slider-arrow.next {
  right: 10px;
}
.slider-arrow.prev {
  left: 10px;
}

.img-part {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 500px;
}

.img-part img {
  max-width: 100%;
  max-height: 100%;
  width: 30rem;
  height: 30rem;
  object-fit: contain;
}

.post-comment-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.media-row {
  display: flex;
  flex-wrap: wrap;
}

.media-container {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 5px;
  position: relative;
}

.post-image {
  width: 100%; /* Ensure images take the full width of the container */
  height: auto; /* Maintain the aspect ratio */
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
.remove-image-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff0000; /* Customize the button background color */
  color: #fff; /* Customize the button text color */
  border: none;
  /* padding: 5px; */
  cursor: pointer;
}

.about-content > h5 {
  text-align: justify;
  text-transform: capitalize;
  text-indent: 3rem;
}
.about-content > h5::before {
  content: '"';
  font-size: 1.2rem;
}
.about-content > h5::after {
  content: '"';
  font-size: 1.2rem;
}

.login-h3 {
  font-weight: bold;
}

.tip-switch {
  cursor: pointer;
}
.tip-switch:checked {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.support-image {
  max-height: 20rem;
  object-fit: contain;
  max-width: 100%;
}

@media (max-width: 576px) {
  .login-img {
    max-width: 50% !important;
  }

  .login-h3 {
    font-size: 0.8rem !important;
    display: none;
  }
}
.error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
